import React from 'react';
import { Link } from 'gatsby';
import { Layout } from 'components';
import { StaticImage } from 'gatsby-plugin-image';
import { container, image, content } from './404.module.scss';

const FourOhFour = ({ location }) => (
  <Layout location={location}>
    <div className={`container ${container}`}>
      <StaticImage alt="Oops this page has gone missing" className={image} placeholder="NONE" src="../images/404.svg" />
      <div className={content}>
        <h2>404 Not found</h2>
        <Link className="button" to="/">
          Go back home
        </Link>
      </div>
    </div>
  </Layout>
);

export default FourOhFour;
